import * as React from "react";

const SVGLcdBgPoly2 = (props) => (
	<svg
		width={269}
		height={588}
		viewBox="0 0 269 588"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M363.784 145.924C367.357 145.924 370.658 147.83 372.444 150.924L465.694 312.436C467.48 315.53 467.48 319.342 465.694 322.436L372.444 483.948C370.658 487.042 367.357 488.948 363.784 488.948L177.286 488.948C173.713 488.948 170.412 487.042 168.626 483.948L75.3764 322.436C73.5901 319.342 73.5901 315.53 75.3764 312.436L168.626 150.924C170.412 147.83 173.713 145.924 177.286 145.924L363.784 145.924Z"
			fill="#EEEEEE"
			fillOpacity={0.3}
		/>
		<path
			d="M369.714 99.0227C373.287 99.0227 376.588 100.929 378.374 104.023L471.623 265.535C473.41 268.629 473.41 272.441 471.623 275.535L378.374 437.047C376.588 440.141 373.287 442.047 369.714 442.047L183.216 442.047C179.643 442.047 176.342 440.141 174.555 437.047L81.3061 275.535C79.5198 272.441 79.5198 268.629 81.3061 265.535L174.555 104.023C176.342 100.929 179.643 99.0227 183.216 99.0227L369.714 99.0227Z"
			fill="#EEEEEE"
			fillOpacity={0.3}
		/>
	</svg>
);

export default SVGLcdBgPoly2;
