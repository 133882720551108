import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "./link";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "react-bootstrap/Button";
import SVGDottedPattern from "./SVG/dotted-pattern";
import SVGPolygon from "./SVG/polygon";

const TextOnly = ({
	heading,
	text,
	img,
	cta,
	ctaUrl,
	height,
	imgAlt,
	width,
	bColour,
	fw,
	mobileOff,
}) => {
	const newHeight = height || "30rem";
	const newWidth = width || "30rem";
	return (
		<section className={`my-lg-6 ${mobileOff ? "pt-3" : ""} `}>
			<Container>
				<Row className="align-items-center">
					<Col
						className="pe-xl-5 text-md-center text-lg-start pt-md-3 pt-lg-5 pt-xl-0"
						xs={12}
					>
						<div className="position-relative">
							<SVGPolygon
								className="position-absolute d-none d-lg-block"
								style={{ zIndex: -1, left: "-1rem", top: "-2.5rem" }}
							/>
							<h2 className=" text-start  mb-3">{heading}</h2>
						</div>
						<hr className="hr-primary" />

						<p className="text-start mb-4">{text}</p>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default TextOnly;
