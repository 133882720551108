import * as React from "react";

const SVGLcdBgPoly4 = (props) => (
	<svg
		width={513}
		height={1091}
		viewBox="0 0 513 1091"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M177.744 270.684C181.317 270.684 184.618 272.59 186.404 275.684L364.315 583.834C366.101 586.928 366.101 590.74 364.315 593.834L186.404 901.983C184.618 905.077 181.317 906.983 177.744 906.984L-178.077 906.984C-181.649 906.984 -184.951 905.078 -186.737 901.983L-364.647 593.834C-366.434 590.74 -366.434 586.928 -364.647 583.834L-186.737 275.684C-184.951 272.59 -181.649 270.684 -178.077 270.684L177.744 270.684Z"
			fill="#EEEEEE"
			fillOpacity={0.3}
		/>
		<path
			d="M188.744 183.684C192.317 183.684 195.618 185.59 197.404 188.684L375.315 496.834C377.101 499.928 377.101 503.74 375.315 506.834L197.404 814.983C195.618 818.077 192.317 819.983 188.744 819.984L-167.077 819.984C-170.649 819.984 -173.951 818.078 -175.737 814.983L-353.647 506.834C-355.434 503.74 -355.434 499.928 -353.647 496.834L-175.737 188.684C-173.951 185.59 -170.649 183.684 -167.077 183.684L188.744 183.684Z"
			fill="#EEEEEE"
			fillOpacity={0.3}
		/>
	</svg>
);

export default SVGLcdBgPoly4;
