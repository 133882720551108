import * as React from "react";

const SVGLcdBgPoly3 = (props) => (
	<svg
		width={367}
		height={1091}
		viewBox="0 0 367 1091"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M679.744 270.684C683.317 270.684 686.618 272.59 688.404 275.684L866.315 583.834C868.101 586.928 868.101 590.74 866.315 593.834L688.404 901.983C686.618 905.077 683.317 906.983 679.744 906.984L323.923 906.984C320.351 906.984 317.049 905.078 315.263 901.983L137.353 593.834C135.566 590.74 135.566 586.928 137.353 583.834L315.263 275.684C317.049 272.59 320.351 270.684 323.923 270.684L679.744 270.684Z"
			fill="#EEEEEE"
			fillOpacity={0.3}
		/>
		<path
			d="M690.744 183.684C694.317 183.684 697.618 185.59 699.404 188.684L877.315 496.834C879.101 499.928 879.101 503.74 877.315 506.834L699.404 814.983C697.618 818.077 694.317 819.983 690.744 819.984L334.923 819.984C331.351 819.984 328.049 818.078 326.263 814.983L148.353 506.834C146.566 503.74 146.566 499.928 148.353 496.834L326.263 188.684C328.049 185.59 331.351 183.684 334.923 183.684L690.744 183.684Z"
			fill="#EEEEEE"
			fillOpacity={0.3}
		/>
	</svg>
);

export default SVGLcdBgPoly3;
